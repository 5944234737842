<template>
	<div>
		<div class="row mt-4 mb 4">
			<div class="col-sm-12 text-right">
				<h1>Catálogo - Tipos de contacto</h1>
			</div>
		</div>

		<DataGrid :config="dataGridConfig" :data="tipos_contactos" :select="seleccionar" @actions="dataGridActions">
			<div class="row form-group">
				<div class="col-sm-2">
					<button class="btn principal-btn" @click="nuevo_registro_modal=true">Nuevo registro</button>
				</div>
				<div class="col-sm-2">
					<button class="btn warning-btn" @click="recargar_registros">Recargar</button>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="nuevo_registro_modal" @close="cancelar_nuevo_registro">
			<div class="title">Nuevo registro</div>
			<form @submit="guardar_nuevo_registro">
				<div class="body">
						<div class="row form-group">
							<label for="codigo" class="col-form-label col-sm-2">Código</label>
							<div class="col-sm-10">
								<input v-model="nuevo_registro.codigo" type="text" name="codigo" id="codigo" class="form-control">
							</div>
						</div>
						<div class="row form-group">
							<label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
							<div class="col-sm-10">
								<input v-model="nuevo_registro.descripcion" type="text" name="descripcion" id="descripcion" class="form-control">
							</div>
						</div>
						<div class="row form-group">
							<label for="estatus" class="col-form-label col-sm-2">Estatus</label>
							<div class="col-sm-10">
								<select v-model="nuevo_registro.estatus" name="estatus" id="estatus" class="form-control">
									<option value="1">Activo</option>
									<option value="0">Inactivo</option>
								</select>
							</div>
						</div>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-2 offset-sm-8">
							<button class="btn secondary-btn" @click="guardar_nuevo_registro">Guardar</button>
						</div>
						<div class="col-sm-2">
							<button class="btn danger-btn" type="button" @click="cancelar_nuevo_registro">Cancelar</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'

	import api from '@/apps/clientes/api/clientes'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'catalogos'
					,cols: {
						codigo: 'Código'
						,descripcion: 'Descripción'
						,estatus: 'Estatus'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						estatus: function(val) {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionadas: []
				,tipo_catalogo: 1
				,tipos_contactos: []
				,nuevo_registro_modal: false
				,nuevo_registro: {
					codigo: null
					,descripcion: null
					,estatus: 1
				}
			}
		}
		,methods: {
			obtener_tipos_clientes: async function() {
				let res = (await api.catalogo_info_contacto(this.options)).data;

				this.tipos_contactos = res.data;
				this.dataGridConfig.paginator.pagina_actual = res.current_page;
				this.dataGridConfig.paginator.total_registros = res.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
			}
			,dataGridActions: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this.obtener_tipos_clientes();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,recargar_registros: function() {
				if (this.tipo_catalogo == 1)
					this.obtener_tipos_clientes();
			}
			,cancelar_nuevo_registro: function() {
				this.nuevo_registro = {
					codigo: null
					,descripcion: null
					,estatus: 1
				}
				this.nuevo_registro_modal = false;
			}
			,guardar_nuevo_registro: async function(e) {
				if (e) e.preventDefault();

				if (!this.nuevo_registro.codigo || this.nuevo_registro.codigo.lenght < 3)
					return this.$helper.showMessage('Error','Tienes que definir un código del al menos 3 digitos','error','alert');

				if (!this.nuevo_registro.descripcion || this.nuevo_registro.descripcion.lenght < 3)
					return this.$helper.showMessage('Error','Tienes que definir la descripción del tipo de contacto','error','alert');

				try {
					await api.crear_tipo_contacto(this.nuevo_registro);
					this.cancelar_nuevo_registro();
					this.obtener_tipos_clientes();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
		}
	}
</script>